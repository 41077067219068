import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Mandate = ({ data }) => {
  const { section2Content } = data
  return (
    <div className="mandate_section about_page_mandate_section">
      <div className="container">
        <div className="mandate_block">
          <div className="mandate_top">{renderRichText(section2Content)}</div>
        </div>
      </div>
    </div>
  )
}

export default Mandate
