import React from "react"

const Hero = ({ data }) => {
  return (
    <div className="common_banner_section">
      <div className="container">
        <div className="banner_center_section">
          <h1>{data.section1Title}</h1>
          <div className="banner_info">
            <p>{data.section1Content.section1Content}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
