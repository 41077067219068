import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BoardOfDirectors = ({ data }) => {
  const { section3Title1, section3Title2, directors } = data
  return (
    <div className="bod_section" id="board-of-directors">
      <div className="fp_image">
        <img src="assets/image/About/fingure_print.png" alt="Fingureprint" />
      </div>
      <div className="container">
        <div className="bod_block">
          <div className="common_section_title">
            <h2>
              {section3Title1} <span>{section3Title2}</span>
            </h2>
          </div>
          <div className="bod_box_block">
            <div className="bod_box_inner">
              {directors.map(director => (
                <div className="bod_box_col" key={director.id}>
                  <div className="bod_box show_modal">
                    <a
                      href={director.socialMedia}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="bod_img_box">
                        <GatsbyImage
                          image={getImage(director.image)}
                          alt="director"
                        />
                      </div>
                      <div className="bod_box_info">
                        <h5>{director.name}</h5>
                        <p>{director.designation}</p>
                        {director.additionalPosition !== "#" && (
                          <p className="officer-title">{director.additionalPosition}</p>
                        )}
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className="loadmore_btn">
              <a
                href="#"
                target="_blank"
                rel="noreferrer"
                className="load_more"
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoardOfDirectors
