import React, { useRef, useEffect } from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { INLINES } from "@contentful/rich-text-types"

const Career = ({ data }) => {
  const { section4Image, section4Title, section4ShortText, section4Content } =
    data

  const div = useRef()

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: node => {
        return (
          <a href={node.data.uri} target="_blank">
            {node.content[0].value}
          </a>
        )
      },
    },
  }
  
  return (
    <div className="work_section">
      <div className="container">
        <div className="work_inner_block">
          <div className="work_left_block">
            <div className="bg_image">
              <div className="top_layer_image">
                <GatsbyImage image={getImage(section4Image)} alt="toplayer" />
              </div>
            </div>
          </div>
          <div className="work_right_block">
            <div className="work_info">
              <div className="common_title_team_label">
                <div className="commn_title_label">
                  <p>{section4ShortText}</p>
                </div>
                <div className="common_inner_title">
                  <h3>{section4Title}</h3>
                </div>
              </div>
              <p className="work_text"></p>
              <div className="work_info_inner" ref={div}>
                {renderRichText(section4Content, options)}
                {/* <h4>Current position available at COMMB:</h4>
                <a href="#0">
                  Marketing & Communications Specialist
                  <i className="fa fa-caret-right" aria-hidden="true"></i>
                </a>
                <h4>
                  Current positions available in the out of home industry:
                </h4>
                <p>
                  <a href="#0">
                    Sales Director, Media Solutions (Toronto)
                    <span> — Stingray</span>
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  </a>
                </p>
                <p>
                  <a href="#0">
                    Campaign Manager, Media Solutions (Toronto Or Montreal)
                    <span> — Stingray</span>
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Career
