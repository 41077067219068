import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"

const Partners = ({ data }) => {
  const {
    section6ShortText,
    section6Title,
    section6Content1,
    section6Content2,
    section6Content3,
  } = data

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: node => {
        return (
          <a href={node.data.uri} target="_blank">
            {node.content[0].value}
          </a>
        )
      },
    },
  }
  return (
    <div className="partner_section">
      <div className="container">
        <div className="partner_title">
          <div className="common_title_team_label">
            <div className="commn_title_label">
              <p>{section6ShortText}</p>
            </div>
            <div className="common_inner_title">
              <h3>{section6Title}</h3>
            </div>
          </div>
        </div>
        <div className="all_ul_blocks">
          <div className="ul_block_inner">
            <div className="three_ul_block">
              <div className="partner_blocks">
                {renderRichText(section6Content1, options)}
              </div>
            </div>
            <div className="three_ul_block">
              <div className="partner_blocks">
                {renderRichText(section6Content2, options)}
              </div>
            </div>
            <div className="three_ul_block">
              <div className="partner_blocks bottom_mrgn">
                {renderRichText(section6Content3, options)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners
