import React, { useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const OurTeam = ({ data }) => {
  const {
    section5Title1,
    section5Title2,
    section5Cards,
    section5Info,
    section5InfoLabel,
    section5InfoUrl,
  } = data

  useEffect(() => {
    // Scroll Slider
    const slider = document.querySelector(".common_slider")
    let isDown = false
    let startX
    let scrollLeft

    slider.addEventListener("mousedown", e => {
      isDown = true
      slider.classList.add("active")
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    })
    slider.addEventListener("mouseleave", () => {
      isDown = false
      slider.classList.remove("active")
    })
    slider.addEventListener("mouseup", () => {
      isDown = false
      slider.classList.remove("active")
    })
    slider.addEventListener("mousemove", e => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = (x - startX) * 1 //scroll-fast
      slider.scrollLeft = scrollLeft - walk
      console.log(walk)
    })
  }, [])

  return (
    <div className="our_team_section">
      <div className="container">
        <div className="out_team_top">
          <div className="common_title_team_label">
            <div className="common_section_title">
              <h2>
                {section5Title1} <span>{section5Title2}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="common_slider_section">
        <div className="cm_pad">
          <div className="common_slider cm_slick_slider">
            {section5Cards.map(card => (
              <div className="slide_box" key={card.id}>
                <a href={card.socialMedia} target="_blank">
                  <div className="box_image">
                    {/* <img
                      src="assets/image/About/dummy_img_1.png"
                      alt="slider_image"
                      className="slider_images"
                    /> */}
                    <GatsbyImage
                      image={getImage(card.image)}
                      alt={card.title}
                      className="slider_images"
                    />
                  </div>
                  <div className="box_info">
                    <h5>{card.title}</h5>
                    <div className="teamTitleWrapper">
                      {renderRichText(card.description)}
                    </div>
                    <p className="emailLink">
                      <span>{card.url}</span>
                    </p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="common_slider_track">
          <div className="container">
            <div className="mail_block">
              <p>
                {section5Info} <a href={section5InfoUrl}>{section5InfoLabel}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurTeam
