import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Hero from "../components/about/hero"
import Mandate from "../components/about/mandate"
import BoardOfDirectors from "../components/about/board_of_directors"
import Career from "../components/about/career"
import OurTeam from "../components/about/our_team"
import Partners from "../components/about/partners"

const About = ({ data }) => (
  <div className="aboutPage">
    <Layout>
      <Seo title="About" description="COMMB is the national not-for-profit organization for the Canadian out-of-home (OOH) industry. Our membership base is comprised of advertisers, agencies, programmatic tech-stacks and OOH companies, large and small."/>
      <div className="main_content_wrapper about_page">
        <Hero data={data.contentfulAbout} />
        <Partners data={data.contentfulAbout} />

        <BoardOfDirectors data={data.contentfulAbout} />
        <Career data={data.contentfulAbout} />
        <OurTeam data={data.contentfulAbout} />
        <Mandate data={data.contentfulAbout} />
      </div>
    </Layout>
  </div>
)

export default About

export const query = graphql`
  query About($language: String) {
    contentfulAbout(node_locale: { eq: $language }) {
      section1Title
      section1Content {
        section1Content
      }

      section6ShortText
      section6Title
      section6Content1 {
        raw
      }
      section6Content2 {
        raw
      }
      section6Content3 {
        raw
      }

      section3Title1
      section3Title2
      directors {
        id
        name
        designation
        socialMedia
        image {
          gatsbyImageData
        }
        additionalPosition
      }

      section4Image {
        gatsbyImageData
      }
      section4Title
      section4ShortText
      section4Content {
        raw
      }

      section5Title1
      section5Title2
      section5Cards {
        id
        title
        socialMedia
        description {
          raw
        }
        url
        image {
          gatsbyImageData
        }
      }
      section5Info
      section5InfoLabel
      section5InfoUrl

      section2Content {
        raw
      }
    }
  }
`
